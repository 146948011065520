/** 
 * 视听云---申请
 */
<template>
  <v-app class="container">
    <div class="appWrap">
      <div class="barCon">
        <div class="tit">申请云视听服务</div>
        <div class="contentWrap">
          <p>{{ data.app.tip }}</p>
          <div v-for="(n,i) in data.app.text" :key="i">{{n}}</div>
        </div>
      </div>
      <div class="mainCon">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <div class="childTitle">完善基本信息</div>
          <div class="main-item">
            <el-form-item label="公司名称：" prop="name" class="fontitem" >
              <el-input v-model="ruleForm.name" placeholder="请输入公司全称" style="width: 350px"></el-input>
            </el-form-item>
            <el-form-item label="微信号：" prop="WChat" class="fontitem">
              <el-input v-model="ruleForm.WChat" placeholder="请输入微信号" style="width: 350px"></el-input>
            </el-form-item>
            <el-form-item 
                label="联系人手机号：" 
                prop="phone" 
                class="fontitem"
                >
              <el-input v-model="ruleForm.phone" placeholder="请输入手机号" style="width: 350px"></el-input>
            </el-form-item>
          </div>

        <div class="childTitle">公有云Bucket配置</div>
        <div  class="main-item"> 
          <el-form-item label="公有云服务商：" prop="region" class="fontitem">
            <el-select 
              v-model="ruleForm.region" 
              @change="handleChangeTypeValue"
              placeholder="请选择活动区域">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="serveDetail fontitem" >
            <div v-if="switchType === 'alObject'">
              <el-form-item label="AccessKeyId：" prop="key1">
                <el-input v-model="ruleForm.key1" placeholder="请输入AccessKeyId：" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="AccessKeySecret：" prop="key2">
                <el-input v-model="ruleForm.key2" placeholder="请输入AccessKeySecret" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="Bucket：" prop="key3">
                <el-input v-model="ruleForm.key3" placeholder="请输入Bucket" style="width: 350px"></el-input>
              </el-form-item>
            </div>

            <div v-if="switchType === 'alVideo'">
              <el-form-item label="AccessKeyId" prop="key4">
                <el-input v-model="ruleForm.key4" placeholder="请输入AccessKeyId：" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="SecretAccessKey" prop="key5">
                <el-input v-model="ruleForm.key5" placeholder="请输入SecretAccessKey" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="RegionId" prop="key6">
                <el-input v-model="ruleForm.key6" placeholder="请输入RegionId" style="width: 350px"></el-input>
              </el-form-item>
            </div>

            <div v-if="switchType === 'txObject'">
              <el-form-item label="SecretId" prop="key7">
                <el-input v-model="ruleForm.key7" placeholder="请输入SecretId：" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="SecretKey" prop="key8">
                <el-input v-model="ruleForm.key8" placeholder="请输入SecretKey" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="Bucket" prop="key9">
                <el-input v-model="ruleForm.key9" placeholder="请输入Bucket" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="Region" prop="key10">
                <el-input v-model="ruleForm.key10" placeholder="请输入Region" style="width: 350px"></el-input>
              </el-form-item>
            </div>

            <div v-if="switchType === 'txVideo'">
              <el-form-item label="SecretId" prop="key11">
                <el-input v-model="ruleForm.key11" placeholder="请输入SecretId：" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="SecretKey：" prop="key12">
                <el-input v-model="ruleForm.key12" placeholder="请输入SecretKey" style="width: 350px"></el-input>
              </el-form-item>
            </div>

            <div v-if="switchType === 'qnObject'">
              <el-form-item label="AccessKeyId" prop="key13">
                <el-input v-model="ruleForm.key13" placeholder="请输入AccessKeyId：" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="SecretKey" prop="key14">
                <el-input v-model="ruleForm.key14" placeholder="请输入SecretKey" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="Bucket：" prop="key15">
                <el-input v-model="ruleForm.key15" placeholder="请输入Bucket" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="BaseUrl" prop="key16">
                <el-input v-model="ruleForm.key16" placeholder="请输入BaseUrl" style="width: 350px"></el-input>
              </el-form-item>
            </div>
          
            <div v-if="switchType === 'hwObject'">
              <el-form-item label="Access_key_id" prop="key17">
                <el-input v-model="ruleForm.key17" placeholder="请输入Access_key_id：" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="Secret_access_key" prop="key18">
                <el-input v-model="ruleForm.key18" placeholder="请输入Secret_access_key" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="Bucket：" prop="key19">
                <el-input v-model="ruleForm.key19" placeholder="请输入Bucket" style="width: 350px"></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="childTitle">套餐及内容</div>
        <el-table
          :data="tableData"
          border
          highlight-current-row
          :header-cell-style="{ background: '#fafafa', color: '#555' }"
          style="max-width: 880px;"
        >
          <el-table-column label="套餐类型" width="240" align="center">
            <template slot-scope="scope" class="aaaaaaaabb">
              <div  class="aaaaaaaabb" style="    text-align: start; padding-left:25px;">
              <el-radio
                :label="scope.$index"
                v-model="currentRow"
                @change.native="getCurrentRow(scope.row)"
              > {{ scope.row.name }}</el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="年费（元）" width="180" align="center"></el-table-column>
          <el-table-column prop="Specification" label="规格" align="center"></el-table-column>
        </el-table>
        <div class="tipWrap">
          <div class="tip-item">{{ data.app.contact }}</div>
          <div class="tip-agree">
            <el-checkbox v-model="checked"></el-checkbox>
            <span>我已阅读并同意《视听云平台合规认证服务协议》</span>
          </div>
        </div>
        </el-form>
      </div>
      <div class="btnWrap">
        <!-- <el-button @click="resetForm('ruleForm')" round style="width: 140px">取消</el-button> -->
        <el-button class="btn" type="primary" @click="submitForm('ruleForm')" round style="width: 140px;background:#0568FD">提交</el-button>
      </div>
    </div>

    <!-- 申请开通 -->
    <el-dialog
      :visible.sync="appDialog"
      :close-on-click-modal="false"
      title="申请开通"
      width="670px"
      height="500px"
    >
    <!-- 内部嵌套支付dialog -->
      <el-dialog
        :visible.sync="payDialog"
        :close-on-click-modal="false"
        width="670px"
        :title="payType === 1 ? '支付宝支付' : '微信支付'"
        append-to-body
      >
        <div class="imgWrap"> 
            <img v-if="payType === 1" :src="data.app.ZFBqrcode" alt="">
            <img v-if="payType === 2" :src="data.app.WXqrcode" alt="">
        </div>
        <div class="texts">
          <b>
            <span class="text3">
              支付： <span style="color:red;">¥{{allPrice}}</span> 
            </span>
            <br />
            <div style="height:10px;"></div>
            <span v-if="payType === 1" class="text5">支付宝扫码支付</span>
            <span v-if="payType === 2" class="text5">微信扫码支付</span>
          </b>
        </div>
      </el-dialog>
      <!-- 申请开通内容 -->
      <el-form>
        <el-form-item
          label="支付方式："
          prop="payType"
          label-width="120px"
          class="dialogCon"
        >
          <div class="payTypes">
            <div
              :class="payType === 1 ? 'selectPayType payType' : 'noSelect'"
              @click="changePayType(1)"
            >
              <div class="payImg">
                <img :src="data.app.ZFBpayImg" alt="" />
                支付宝支付
              </div>
            </div>
            <div
              :class="payType === 2 ? 'selectPayType payType' : 'noSelect'"
              @click="changePayType(2)"
            >
              <div class="payImg">
                <img :src="data.app.WXpayImg" alt="" />
                微信支付
              </div>
            </div>
          </div>
        </el-form-item>
        <div class="pay">
            <span >支付金额：</span>            
            <span class="pay1">¥{{allPrice}}</span>
        </div>
        <div class="btn-box">
            <el-button @click="handleShowpay" type="primary" style="border-radius:10px;">立即支付</el-button>
        </div>
      </el-form>
    </el-dialog>
  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';
export default {
  name: "Application",
  data() {  
    var validateMobile = (rule, value, callback) => {
      console.log(value,'value')
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!this.isCellPhone(value)) { 
        callback(new Error('请输入正确的手机号!'))
      } else {
        callback()
      }
    }

    // var validateServices = (rule, value, callback) => {
    //   console.log(value,'value')
    //   console.log(value,'value')
    //   if (value === '') {
    //     callback(new Error(`${value}不可为空`))
    //   }  else {
    //     callback()
    //   }
    // }
    return{
      key:'',
      data:contentData.application,
      currentRow: 0,
      switchType:'alObject',  // 服务商类型
      typeOptions: [
        {
          value: "alObject",
          label: "阿里云对象存储",
        },
        {
          value: "alVideo",
          label: "阿里云视频点播",
        },
        {
          value: "txObject",
          label: "腾讯云对象存储",
        },
        {
          value: "txVideo",
          label: "腾讯云点播",
        },
        {
          value: "qnObject",
          label: "七牛云对象存储",
        },
        {
          value: "hwObject",
          label: "华为云对象存储",
        },
      ],
      rules: {
        name: [
          { required: true, message: '请输入公司名称',  trigger: ['blur', 'change']  },
        ],
        phone: [
          {validator: validateMobile, required: true, trigger: ['blur', 'change'] },
        ],
        region: [
          { required: true, message: '请选择公有云服务商', trigger: 'change' }
        ],

        key1: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key2: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key3: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key4: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key5: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key6: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key7: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key8: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key9: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key10: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key11: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key12: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key13: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key14: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key15: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key16: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key17: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key18: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ],
        key19: [
          { required: true, message: '不可为空', trigger: ['blur', 'change'] }
        ]
      },
      ruleForm: {
        name: '',
        WChat: '',
        phone: '',
        region: 'alObject',
        key1:'',  // 服务商选项
        key2:'',
        key3:'',
        key4:'',
        key5:'',  
        key6:'',
        key7:'',
        key8:'',
        key9:'',  
        key10:'',
        key11:'',
        key12:'',
        key13:'',  
        key14:'',
        key15:'',
        key16:'',
        key17:'',
        key18:'',
        key19:'',
        desc: '',  // 选择公有服务商
        checkedone:'',
        checkedtwo:'',
        checkedthree:'',
      },
      tableData: [{
        date: '2,999',
        name: '存储空间认证基础版',
        index: "1",
        Specification: '含1,000分钟审核时长'
      }, {
        date: '9,999',
        name: '存储空间认证标准版',
        index: "2",
        Specification: '含3,000分钟审核时长'
      }, {
        date: '19,999',
        name: '存储空间认证专业版',
        index: "3",
        Specification: '含6,000分钟审核时长'
      }, {
        date: '99,000',
        name: '平台认证教育版',
        index: "4",
        Specification: '支持直播，审核费1元/分钟'
      }, {
        date: '199,000',
        name: '平台认证标准版',
        index: "5",
        Specification: '支持直播+UGC，审核费1元/分钟'
      }],
      checked: false,
      appDialog:false,
      payDialog:false,
      successsDialog:false,
      currentIndex: 0,
      payType: 1, // 1，支付宝，2微信
      addprice:'',
      allPrice: 0, // 总价
    }
  },
  methods: {
    // 检查手机号
    isCellPhone (val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false
      } else {
        return true
      }
    },

    getCurrentRow(row) {
      console.log(row);
      this.allPrice = row.date
      console.log(this.allPrice,'this.allPrice')
    },
    handleShowpay(){
      this.payDialog = true
    },
    changePayType(type) {
      this.payType = type
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(!this.checked){
            this.$message.warning('请勾选《视听云平台合规认证服务协议》后再提交')
          }else{
            this.appDialog = true
          }
        } else {
          return false;
        }
      });

    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleChangeTypeValue(val){
      switch (val) {
        case "alObject":
          this.switchType = "alObject";
          break;
        case "alVideo":
          this.switchType = "alVideo";
          break;
        case "txObject":
          this.switchType = "txObject";
          break;
        case "txVideo":
          this.switchType = "txVideo";
          break;
        case "qnObject":
          this.switchType = "qnObject";
          break;
        case "hwObject":
          this.switchType = "hwObject";
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  background-color: #fafcff;
  padding-bottom: 40px;
}
.fontitem ::v-deep .el-form-item__label{
  font-size: 14px !important;
}
::v-deep .el-form-item__label{
  font-size: 20px;
}
::v-deep .el-dialog{
  height: 500px;
}
::v-deep .el-dialog__header{
  background: #FAFBFC;
  border: 1px solid #E6E6E6;
}
::v-deep .el-dialog__title{
    font-size: 24px;
    line-height: 30px;
}


.payTypes {
  display: flex;

  .selectPayType {
    width: 160px;
    cursor: pointer;
    border: 2px solid #2f88ff;
    border-radius: 25px;
  }

  .noSelect{
    border: 2px solid #cccccc;
    margin-right: 50px;
    width: 160px;
    height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 25px;
    cursor: pointer;
    .payImg {
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }    
  }

  .payType {
    margin-right: 50px;
    width: 160px;
    height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 25px;
    cursor: pointer;

    .payImg {
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
}

.imgWrap{
  width: 235px;
  position: absolute;
  top: 120px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  img{
    width: 235px;
    height: 235px;
  }
}
.texts {
  width: 95%;
  position: absolute;
  bottom: 50px;
  text-align: center;

  .text3 {
    font-size: 20px;
    color: #2a2a2a;
  }

  .text4 {
    font-size: 20px;
    color: red;
  }

  .text5 {
    font-size: 14px;
  }
}
.dialogCon{
  position: relative;
}
.btn-box{
  width: 95%;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #DEDEDE;
  // border-top: 1px solid red;
  padding-top: 19px;
  position: absolute;
  bottom: 19px;
}
.pay{
  font-size: 20px;
  line-height: 20px;
  margin-top: 100px;
  .pay1{
    font-size: 24px;
    padding-left: 20px;
  }
}


.appWrap{
  background-color: #fff;
  width: 1200px;
  margin: 0 auto;
  // padding: 30px 40px 80px 40px;
  padding: 30px 0px;
  .childTitle{
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    line-height: 40px;
    margin-bottom: 15px;
  }
  .main-item{
    padding-left: 120px;
  }
  .barCon{
    padding: 0px 40px;
    .tit{
      font-size: 20px;
      color: #000000;
      line-height: 34px;
      font-weight: 500px;
    }
    .contentWrap{
      width: 1120px;
      height: 167px;
      background: #FFFCF4;
      border-radius: 4px;
      font-size: 14px;
      color: #000000;
      line-height: 20px;
      padding: 26px 62px 26px 30px;
      margin-top: 31px;
    }
  }
  .mainCon{
    position: relative;
    margin-left: 130px;
    margin-top: 30px;
    padding: 0px 40px;
    .tipWrap{
      .tip-item{
        padding: 14px 0;
      }
      .tip-agree{
        padding-bottom: 73px;
        line-height: 12px;
        span{
          padding-left: 5px;
        }
      }
    }
  }
  .btnWrap{
    width: 1200px;
    margin:  auto;
    display: flex;
    justify-content: center;
  }

}
</style>